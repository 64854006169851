.identityTable {
  width: 90%;
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  line-height: normal;
}
.identityHeadersRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 0.8rem;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #111;
  font-weight: bold;
}
.identityHeader {
  line-height: 1.6rem;
  position: relative;
  bottom: 0;
  padding: 0.8rem 0 0.4rem 0;
}
.identityDataBody {
  margin-top: 1.6rem;
}
.identityDataRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  word-wrap: break-word;
  grid-gap: 0.8rem;
  margin-bottom: 5px;
}
