.dataTable {
  width: 90%;
  margin-bottom: 2.4rem;
  border-spacing: 0px;
  font-size: 1.4rem;
}
.headerRow {
  text-align: left;
  height: 3.2rem;
}
.headerField {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #111;
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
}
.dataRows {
  height: 4.8rem;
}
.dataRows:first-child td {
  border-top: 0px;
}
.dataRows:last-child {
  margin-bottom: 1.6rem;
}
.dataField {
  text-align: left;
  overflow-wrap: break-word;
  border-top: 1px solid #ebebeb;
  max-width: 20rem;
  vertical-align: top;
  padding-top: 1.6rem;
  padding-left: 0.8rem;
}
