@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
 html {
	 font-size: 62.5%;
}
 body {
	 font-family: Cern, Helvetica, Arial, sans-serif;
	 color: #111;
	 font-size: 1.6rem;
	 line-height: 2.4rem;
}
 h1 {
	 font-size: 6rem;
	 font-weight: 800;
	 line-height: 1.2;
}
 h2 {
	 font-size: 3.6rem;
	 font-weight: 800;
	 line-height: 1.33;
}
 h3 {
	 font-size: 3.6rem;
	 line-height: 1.33;
	 font-weight: normal;
}
 h4 {
	 font-size: 2.4rem;
	 line-height: 1.33;
	 font-weight: normal;
}
 h4.emphasis {
	 font-weight: 800;
}
 h5 {
	 font-size: 1.6rem;
	 font-weight: 600;
	 line-height: 1.5;
}
 h6 {
	 font-family: Inconsolata, Consolas, Courier, monospace;
	 font-size: 1.4rem;
	 line-height: 1.33;
	 letter-spacing: 1px;
	 font-weight: bold;
	 text-transform: uppercase;
}
 p {
	 font-size: 1.6rem;
	 line-height: 1.5;
	 color: #4b4b4b;
}
 a {
	 color: #111;
	 text-decoration: none;
}
 small {
	 font-size: 1.2rem;
	 line-height: 1.33;
	 letter-spacing: 0.25px;
}
 code {
	 font-family: Inconsolata, Consolas, Courier, monospace;
	 font-size: 1.4rem;
	 white-space: pre-wrap;
	 word-break: break-word;
	 background-color: #f6f6f6;
	 border-radius: 0.2rem;
	 padding-bottom: 0.2rem;
	 padding-left: 0.4rem;
	 padding-right: 0.4rem;
	 padding-top: 0.2rem;
	 color: #111;
}
 em {
	 font-style: italic;
}
 b, strong {
	 font-weight: 600;
}
 
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

* {
  font-family: Montserrat, Inter, sans-serif;
}

#root {
  zoom: 90%;
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 1.6rem;
}
.title {
  margin-top: 7.2rem;
  margin-bottom: 0;
  font-weight: 800;
  height: 4.8rem;
}
.subtitle {
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.introPar {
  width: 100%;
  font-size: 1.6rem;
  margin: 1.6rem 0;
}
.linkButton {
  margin-top: 2.4rem;
}
.itemAccessContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-shadow: 0 0.8rem 0.8rem 0 rgba(17, 17, 17, 0.08);
  margin-top: 2.4rem;
  border-radius: 2px;
}
.itemAccessRow {
  display: flex;
  border: 1px solid #ebebeb;
  height: 50%;
  margin: 0;
}
.itemAccessRow:last-child {
  border-top: 0px;
}
.idName {
  padding: 1.6rem 2.4rem 1.6rem 4rem;
  flex: 1 1;
  font-weight: bold;
  font-family: Inconsolata, Consolas, Courier, monospace;
  color: #111;
}
.tokenText {
  padding: 1.6rem 2.4rem 1.6rem 0;
  flex: 5 1;
  font-family: Inconsolata, Consolas, Courier, monospace;
}
.requests {
  margin-top: 5.6rem;
  font-size: 1.6rem;
}

.identityTable {
  width: 90%;
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  line-height: normal;
}
.identityHeadersRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 0.8rem;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #111;
  font-weight: bold;
}
.identityHeader {
  line-height: 1.6rem;
  position: relative;
  bottom: 0;
  padding: 0.8rem 0 0.4rem 0;
}
.identityDataBody {
  margin-top: 1.6rem;
}
.identityDataRow {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  word-wrap: break-word;
  grid-gap: 0.8rem;
  margin-bottom: 5px;
}

.dataTable {
  width: 90%;
  margin-bottom: 2.4rem;
  border-spacing: 0px;
  font-size: 1.4rem;
}
.headerRow {
  text-align: left;
  height: 3.2rem;
}
.headerField {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #111;
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
}
.dataRows {
  height: 4.8rem;
}
.dataRows:first-child td {
  border-top: 0px;
}
.dataRows:last-child {
  margin-bottom: 1.6rem;
}
.dataField {
  text-align: left;
  overflow-wrap: break-word;
  border-top: 1px solid #ebebeb;
  max-width: 20rem;
  vertical-align: top;
  padding-top: 1.6rem;
  padding-left: 0.8rem;
}

.errorTop {
  width: 90%;
  height: 1px;
  border-top: 1px solid #ebebeb;
}
.errorContainer {
  display: grid;
  grid-template-columns: 15% 57% 28%;
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
}
.code {
  margin: 1.6rem;
  font: Inconsolata, Consolas, Courier, monospace;
  letter-spacing: 1px;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
}
.errorContents {
  margin: 2.4rem 3.2rem;
}
.errorItem {
  display: grid;
  grid-template-columns: 2fr 5fr;
  margin-bottom: 0.8rem;
}
.errorTitle {
  font-weight: bold;
  line-height: normal;
}
.errorData {
  line-height: normal;
  font-family: Inconsolata, Consolas, Courier, monospace;
  letter-spacing: 0.25px;
}
.errorCode {
  display: inline-block;
  position: relative;
}
.pinkBox {
  position: absolute;
  top: 0;
  height: 16px;
  width: 100%;
  background-color: #ffd7dc;
  z-index: -1;
}
.errorMessage {
  line-height: normal;
}
.learnMore {
  margin: 1.6rem;
  margin-left: 2.4rem;
  width: 70%;
}

.endpointContainer {
  display: grid;
  grid-template-columns: 15% 57% 28%;
  width: 100%;
  border-top: 1px solid #ebebeb;
}
.post {
  margin: 1.6rem;
  letter-spacing: 1px;
}
.endpointContents {
  margin: 2.4rem 3.2rem;
}
.endpointHeader {
  margin-bottom: 0.8rem;
}
.endpointName {
  font-weight: bold;
  font-size: 1.6rem;
  padding-right: 0.8rem;
}
.schema {
  font-size: 1.6rem;
  font-family: Inconsolata, Consolas, Courier, monospace;
  letter-spacing: -0.24px;
}
.endpointDescription {
  font-size: 1.4rem;
  line-height: 1.6rem;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
}
.sendRequest {
  margin: 1.6rem 1.6rem 0.8rem 2.4rem;
  width: 70%;
}
.pdf {
  margin: 0 1.6rem 0 2.4rem;
  width: 70%;
}

.container-products {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.8rem 0.8rem 0 rgba(17, 17, 17, 0.08);
  margin: 0 0 2.4rem 0;
}
.header {
  width: 100%;
  border-bottom: 0px;
  height: 10.4rem;
  font-weight: 800;
  padding-top: 4rem;
  padding-left: 4rem;
  margin-top: 0;
  margin-bottom: 0;
}

.header-second {
  border-top: 1px solid #ebebeb;
  width: 95%;
  border-bottom: 0px;
  font-weight: 800;
  padding-top: 4rem;
  padding-left: 4rem;
  margin-top: 0;
  margin-bottom: 10px;
}

.map-selected {
  fill: #18ca92;
}

.map-unselected {
  fill: #000;
}

.map-img {
  width: 380px;
  height: auto;
  left: -10px;
  position: relative;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 56rem;
  max-width: 75.2rem;
  margin: 0 auto;
}

