.container-products {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.8rem 0.8rem 0 rgba(17, 17, 17, 0.08);
  margin: 0 0 2.4rem 0;
}
.header {
  width: 100%;
  border-bottom: 0px;
  height: 10.4rem;
  font-weight: 800;
  padding-top: 4rem;
  padding-left: 4rem;
  margin-top: 0;
  margin-bottom: 0;
}

.header-second {
  border-top: 1px solid #ebebeb;
  width: 95%;
  border-bottom: 0px;
  font-weight: 800;
  padding-top: 4rem;
  padding-left: 4rem;
  margin-top: 0;
  margin-bottom: 10px;
}

.map-selected {
  fill: #18ca92;
}

.map-unselected {
  fill: #000;
}

.map-img {
  width: 380px;
  height: auto;
  left: -10px;
  position: relative;
}