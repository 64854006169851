.errorTop {
  width: 90%;
  height: 1px;
  border-top: 1px solid #ebebeb;
}
.errorContainer {
  display: grid;
  grid-template-columns: 15% 57% 28%;
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
}
.code {
  margin: 1.6rem;
  font: Inconsolata, Consolas, Courier, monospace;
  letter-spacing: 1px;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
}
.errorContents {
  margin: 2.4rem 3.2rem;
}
.errorItem {
  display: grid;
  grid-template-columns: 2fr 5fr;
  margin-bottom: 0.8rem;
}
.errorTitle {
  font-weight: bold;
  line-height: normal;
}
.errorData {
  line-height: normal;
  font-family: Inconsolata, Consolas, Courier, monospace;
  letter-spacing: 0.25px;
}
.errorCode {
  display: inline-block;
  position: relative;
}
.pinkBox {
  position: absolute;
  top: 0;
  height: 16px;
  width: 100%;
  background-color: #ffd7dc;
  z-index: -1;
}
.errorMessage {
  line-height: normal;
}
.learnMore {
  margin: 1.6rem;
  margin-left: 2.4rem;
  width: 70%;
}
