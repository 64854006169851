 html {
	 font-size: 62.5%;
}
 body {
	 font-family: Cern, Helvetica, Arial, sans-serif;
	 color: #111;
	 font-size: 1.6rem;
	 line-height: 2.4rem;
}
 h1 {
	 font-size: 6rem;
	 font-weight: 800;
	 line-height: 1.2;
}
 h2 {
	 font-size: 3.6rem;
	 font-weight: 800;
	 line-height: 1.33;
}
 h3 {
	 font-size: 3.6rem;
	 line-height: 1.33;
	 font-weight: normal;
}
 h4 {
	 font-size: 2.4rem;
	 line-height: 1.33;
	 font-weight: normal;
}
 h4.emphasis {
	 font-weight: 800;
}
 h5 {
	 font-size: 1.6rem;
	 font-weight: 600;
	 line-height: 1.5;
}
 h6 {
	 font-family: Inconsolata, Consolas, Courier, monospace;
	 font-size: 1.4rem;
	 line-height: 1.33;
	 letter-spacing: 1px;
	 font-weight: bold;
	 text-transform: uppercase;
}
 p {
	 font-size: 1.6rem;
	 line-height: 1.5;
	 color: #4b4b4b;
}
 a {
	 color: #111;
	 text-decoration: none;
}
 small {
	 font-size: 1.2rem;
	 line-height: 1.33;
	 letter-spacing: 0.25px;
}
 code {
	 font-family: Inconsolata, Consolas, Courier, monospace;
	 font-size: 1.4rem;
	 white-space: pre-wrap;
	 word-break: break-word;
	 background-color: #f6f6f6;
	 border-radius: 0.2rem;
	 padding-bottom: 0.2rem;
	 padding-left: 0.4rem;
	 padding-right: 0.4rem;
	 padding-top: 0.2rem;
	 color: #111;
}
 em {
	 font-style: italic;
}
 b, strong {
	 font-weight: 600;
}
 